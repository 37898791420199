/* eslint-disable snakecasejs/snakecasejs */

import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { configure, infiniteHits, stats } from 'instantsearch.js/es/widgets';
import { customSearchBox, customRefinementList } from './components';

( () => {
	const search_container = document.getElementById( 'js-search' );
	if ( !search_container ) {
		return;
	}

	const algoliaClient = algoliasearch(
		search_container.dataset.algoliaAppId,
		search_container.dataset.algoliaSearchApiKey
	);

	const searchClient = {
		...algoliaClient,
		search( requests ) {
			if ( requests.every( ( { params } ) => !params.query ) ) {
				return Promise.resolve( {
					results: requests.map( () => ( {
						hits: [],
						nbHits: 0,
						nbPages: 0,
						page: 0,
						processingTimeMS: 0,
						hitsPerPage: 0,
						exhaustiveNbHits: false,
						query: '',
						params: '',
					} ) ),
				} );
			}

			return algoliaClient.search(requests);
		},
	};

	const indexName = search_container.dataset.algoliaIndexName;

	// We disable snake_case so we can use destructuring below
	const search = instantsearch( {
		indexName,
		searchClient,
		routing: true,
	} );

	search.addWidgets( [
		configure( {
			hitsPerPage: 36,
		} ),
		customSearchBox( {
			container: '#js-search-input',
			placeholder: 'Search',
			cssClasses: {
				form: 'search-box__form',
				input: 'search-box__input',
				reset: 'search-box__reset',
				resetIcon: 'search-box__reset-icon',
				submit: 'search-box__submit',
				submitIcon: 'search-box__submit-icon',
			}
		} ),
		infiniteHits( {
			container: '#js-search-results-receptacle',
			cssClasses: {
				loadMore: 'search-box__show-more-container'
			},
			templates: {
				item( hit, { html } ) {
					if ( Object.prototype.hasOwnProperty.call( hit, 'listing_image' )
						&& hit.listing_image !== null
						&& hit.listing_image.length > 0
					) {
						return html`
						<a href="${ hit.url }" class="search-box__results-receptacle__item">
							<div class="a12-12">
								<div class="grid flex flex--wrap">
									<div class="grid__item a6-8 d10-12 g6-6">
										<div class="search-box__result__inner">
											<p class="search-box__result__section">${ hit.section_handle }</p>
											<p class="search-box__result__title">${ hit.title }</p>
											<p class="search-box__result__link">View Project</p>
										</div>
									</div>
									<div class="grid__item a2-8 d10-12 g6-6">
										<img class="search-box__result__image" src="${ hit.listing_image }" alt />
									</div>
								</div>
							</div>
						</a>
					`;
					}
					return html`
						<a href="${ hit.url }" class="search-box__results-receptacle__item">
							<div class="a5-8 d12-12">
								<div class="search-box__result__inner">
									<p class="search-box__result__section">${ hit.section_handle }</p>
									<p class="search-box__result__title" href="${ hit.url }">${ hit.title }</p>
									<p class="search-box__result__link" href="${ hit.url }">View Project</p>
								</div>
							</div>
						</a>
					`;
				},
				showMoreText( hit, { html }) {
					return html`<div class="search-box__show-more-container"><span class="search-box__show-more-button">View more</span></div>`;
				},
				empty( results, { html } ) {
					return html`<p>No results <q>${results.query.length > 0 ? 'for ' + results.query : '' }</q></p>`;
				}
			}
		} ),
		stats( {
			container: '#js-search-stats',
			cssClasses: {},
			templates: {
				text( data, { html } ) {
					/* eslint-disable quotes */
					let count = '';

					if ( data.hasManyResults ) {
						count += `${data.nbHits} results`;
					} else if ( data.hasOneResult ) {
						count += `1 result`;
					} else {
						count += `No result`;
					}

					if ( data.query ) {
						return html`
							<div class="search-box__info">
								<p class="search-box__title">Results for:<span class="search-box__title-keyword"> ${data.query}</span></p>
								<p class="search-box__results-length">${ count }</p>
							</div>
						`;
					}

				},
			},
		} ),
		customRefinementList( {
			container: document.querySelector( '#filter-list' ),
			attribute: 'section_handle',
			sortBy: ['name:asc']
		} )
	] );

	search.start();
} )();